* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 0;
  padding: 0 16px;
  overflow-x: hidden;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  max-width: 1220px;
  padding: 32px 0;
  margin: 0 auto;
}

.main {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: 'center';
  align-items: center;
  flex: 1;
}

.header-title{
  margin: 0;
  color: #333;
  font-size: 72px;
}

.header-description {
  margin:0;
  color: #aaa;
  font-size: 32px;
  text-align: center;
}

.content-container{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
}

.iPhone-container {
  flex: 1;
  justify-content: flex-end;
  display: flex;
}

.iPhone {
  max-width: 100%;
  height: 700px;
}

.download-container {
  flex: 1
}

.download-button {
  width: auto;
  height: auto;
  max-height: 120px;
}

.footer {
  display: flex;
  justify-content: center;
  flex: 1;
}

.link {
  color: #aaa;
  font-size: 18px;
  padding: 0 24px;
}

@media all and (max-width: 600px) {
  .iPhone-container {
    order: 2;
  }
  .iPhone {
    height: 500px;
  }
  .content-container {
    flex-direction: column;
  }
  .header-title {
    font-size: 48px;
  }
  .header-description{
    font-size: 18px;
    max-width: 80%;
  }
  .download-button{
    height: 60px;
  }
  .download-container {
    order: 1;
  }
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .link {
    padding: 24px;
  }
}

@media all and (min-width: 426px) and (max-width: 768px) {
  .iPhone {
    height: 600px;
  }
  .header-title {
    font-size: 48px;
  }
  .header-description{
    font-size: 18px;
    
  }
  .download-button{
    height: 100px;
  }
}

